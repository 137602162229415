import React from 'react';
import Header from '../components/organisms/headers/Header';
import {NavigationContext} from '../services/navigation';
import {PageContent} from '../components/organisms/Layout';
import useHistoryData from '../queries/useHistoryData';
import {HistoryEvent, Intro, SEO} from '../components/organisms';
import {Testimonial, ContactUs} from '../components/organisms/blocks';

const HistoryPage = () => {
  const data = useHistoryData();
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const {header, intro, testimonial, contactUs, events} = data;
  return (
    <PageContent>
      <SEO
        title={data.SEO?.title}
        description={data.SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: data.SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header?.media?.file?.file?.publicURL}`
          }
        ]}
      />
      {header && <Header header={header} />}
      {intro && <Intro intro={intro} />}
      {testimonial && <Testimonial {...testimonial} />}
      {events && events.map(event => <HistoryEvent key={event.id} {...event}/>)}
      <ContactUs {...contactUs} />
    </PageContent>
  );
};

export default HistoryPage;
