import {graphql, useStaticQuery} from 'gatsby';

const useHistoryData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        historyEvents(sort: "date") {
          ...HistoryEvent
        }
        history {
          SEO {
            ...SEO
          }
          header {
            ...RegularHeader
          }
          intro {
            ...Intro
          }
          testimonial {
            ...Testimonial
          }
          contactUs {
            ...ContactUs
          }
        }
      }
    }
  `);

  return ({
    ...data.strapi.history,
    events: data.strapi.historyEvents
  });
};

export default useHistoryData;
